@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

.frame {
    background-color: white;
    width: 80%;
    height: 350px;
    border: 6px solid;
    /* border-color: #cf9e3e #d0a44c #cf9e3e #d0a44c; */
    border-color: #1d1d1b;
    max-width: 940px;
    padding: 20px;
    /* font-family: 'Kalam', cursive; */
    font-family: 'Playfair Display', serif;
}

.collage {
    background-color: papayawhip;
    background-image: url('../../../images/collage.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    -webkit-box-shadow: 0 0 15px 3px red;
    -moz-box-shadow: 0 0 15px 3px red;
    box-shadow: 0 0 15px 3px red;
    animation: change 5s infinite forwards;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    isolation: isolate;
}

.collage::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: #000;
    z-index: -1;
    opacity: .4;
}

.collage>h1 {
    font-size: 65px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 0;
    text-align: center;
}

.text-cap-color {
    /* color: #d0a44c; */
    color: white;
}

/* @keyframes change {
    0%{
        -webkit-box-shadow: 0 0 5px 2px red;
        -moz-box-shadow: 0 0 5px 2px red;
        box-shadow: 0 0 5px 2px red;
    }
    25%{
        -webkit-box-shadow: 0 0 5px 2px blue;
        -moz-box-shadow: 0 0 5px 2px blue;
        box-shadow: 0 0 5px 2px blue;
    }
    50%{
        -webkit-box-shadow: 0 0 5px 2px pink;
        -moz-box-shadow: 0 0 5px 2px pink;
        box-shadow: 0 0 5px 2px pink;
    }
    100%{
        -webkit-box-shadow: 0 0 5px 2px red;
        -moz-box-shadow: 0 0 5px 2px red;
        box-shadow: 0 0 5px 2px red;
    }
} */
@media screen and (max-width:1000px) {
    .frame {
        height: 280px;
    }

    .collage>h1 {
        font-size: 50px;
    }
}

@media screen and (max-width:768px) {
    .frame {
        width: 100%;
    }

    .collage>h1 {
        width: 100%;
        font-size: 40px;
    }
}

@media screen and (max-width:600px) {
    .collage>h1 {
        font-size: 30px;
    }

    .frame {
        height: 250px;
        padding: 10px;
    }

    .landing-container {
        padding: 0;
    }

    .collage {
        box-shadow: 0 0 5px 2px red;
    }
}

@media screen and (max-width:400px) {
    .frame {
        height: 220px;
    }

    .collage>h1 {
        font-size: 25px;
    }
}
