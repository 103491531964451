@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

.navbar {
  background-color: rgba(29, 29, 27, 0.8);
  max-width: 1500px;
  margin: auto;
  padding: 0 24px;
}

.navbar a {
  font-family: 'Playfair Display', serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
}

.navbar a:active {
  color: #d0a44c;
}

.navbar a:hover {
  color: #d0a44c;
}

.nav-img {
  width: 70px;
}

.dropdown-item {
  text-transform: uppercase;
}

.social-media-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media-nav>a>i {
  font-size: 40px;
}

.insta-margin-left {
  margin-left: 8px;
}

@media screen and (max-width:991px) {
  .social-media-nav {
    justify-content: flex-start;
  }

  .social-media-nav>a>i {
    font-size: 40px;
  }

  .insta-margin-left {
    margin-left: 0;
    margin-top: 8px;
  }

  .navbar-collapse {
    padding-bottom: 15px;
  }
}
