.ktm-event-img-desktop{
    width: 100%;
    opacity:.8;
}
.ktm-event-img-mobile{
    width: 100%;
    opacity:.8;
    display: none;
}

@media screen and (max-width:500px){
    .ktm-event-img-desktop{
        display: none;
    }
    .ktm-event-img-mobile{
        display: block;
    }
}
