@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');
.link-button-url{
    display: flex;
    align-items: center;
    justify-content: center;
}

.link-button-url > a {
    font-family: 'Playfair Display', serif;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 8px;
    width: 160px;
    text-align: center;
    cursor: pointer;
    border: 2px solid #d0a44c;
    color: #d0a44c;
    transition: .3s all ease-in;
}
.link-button-url > a:hover{
    color: white;
    background-color: #d0a44c;
}
