@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

.live-band-heading>h1 {
    font-family: 'Playfair Display', serif;
    margin-bottom: 0;
    font-size: 70px;
    font-weight: bold;
    text-align: center;
}

.live-muisc-section {
    width: 80px;
}

.live-music-container {
    background-image: url('../../../images/live-band-II.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 400px;
    isolation: isolate;
    display: flex;
    justify-content: end;
    align-items: center;
    flex-direction: column;
}

.live-music-container::after {
    position: absolute;
    content: '';
    inset: 0;
    z-index: -1;
    background-color: #000;
    opacity: 0;
}

.live-music-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.day-info,
.time-info {
    border: 2px solid #d0a44c;
}

.day-info>h6,
.time-info>h6 {
    margin-bottom: 0;
    padding: 4px 16px;
    font-weight: bold;
}

.day-info {
    border-left: 0px;
}

.time-info {
    border-left: 0px;
    border-right: 0px;
}

@media screen and (max-width:768px) {
    .live-music-container {
        height: 300px;
    }

    .live-muisc-section {
        width: 55px;
    }

    .live-band-heading>h1 {
        font-size: 50px;
    }
}
