.location-section{
    padding: 15px;
}
.map-iframe-wrapper{
    height: 450px;
}

@media screen and (max-width:768px) {
    .map-iframe-wrapper{
        flex: 1;
        width: 100%;
    }
    .map-iframe-wrapper{
        height: 300px;
    }
}
