.section{
    margin: 2rem 0;
}
.section-heading{
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-img{
    width: 150px;
}
.section-img > img {
    display: block;
    object-fit: cover;
    width: 100%;
    transform: scale(.6);
}
.margin-top-bottom{
    margin-top: 0;
    margin-bottom: 4rem;
}
@media screen and (max-width:768px) {
    .section-img{
        width: 100px;
    }
}
