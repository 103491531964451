.landing-fluid {
    position: relative;
    isolation: isolate;
    min-height: 100vh;
    width: 100%;
    background-image: url(../../images/landing.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 80px;
}

.landing-fluid::after {
    position: absolute;
    content: '';
    inset: 0;
    background-color: #1d1d1b;
    opacity: .3;
    z-index: -100;
}

.landing-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
