@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

.karaoke-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.karaoke-img-container {
    flex: .5;
    position: relative;
}

.karaoke-img-container::after {
    content: '';
    position: absolute;
    inset: 0;
    background-color: #000;
    opacity: .1;
}

.karaoke-info {
    flex: .5;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: column;
    margin-left: 1rem;
}

.karaoke-img-container>img {
    width: 100%;
    object-fit: cover;
}

.karaoke-info>h1 {
    padding: 5px;
    font-size: 60px;
    text-transform: uppercase;
    font-family: 'Playfair Display', serif;
    text-align: center;
}

.karaoke-info>p {
    font-size: 24px;
    font-family: 'Playfair Display', serif;
    margin: 1rem 0;
}

.karaoke-link-margin {
    margin: 1.5rem 0;
}

.karoake-info-img {
    width: 100%;
    ;
    object-fit: cover;
}

.karaoke-info-container {
    margin-top: 3rem;
}

@media screen and (max-width:768px) {
    .karaoke-wrapper {
        flex-direction: column;
    }

    .karaoke-img-container {
        flex: 1;
    }

    .karaoke-info {
        flex: 1;
        padding-left: 0;
        margin-top: 1rem;
        margin-left: 0;
    }

    .karaoke-info>h1 {
        text-align: center;
        font-size: 50px;
    }
}
