.item-list-heading{
    font-weight: bold;
    text-align: center;
    padding: 8px 0;
    border: 1px solid #d0a44c;
    border-width: 1px 0;
    font-size: 24px;
}
.item-list-logo{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;
}
.list-item-grid{
    padding: 10px;
    margin:2rem 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    column-gap: 30px;
    row-gap: 10px;
}

@media screen and (max-width:768px){
    .list-item-grid{
        grid-template-columns: repeat(1,1fr);
    }
}
@media screen and (max-width:500px){
    .item-list-heading{
        font-size: 22px;
    }
}
