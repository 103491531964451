/* @import url(//fonts.googleapis.com/css?family=Vibur); */
@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');
.neon-text-wrapper{
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
}
.logo{
    text-align: center;
    /* font: 400 80px "Vibur"; */
    font-size: 80px;
    font-family: 'Yellowtail', cursive;
    font-weight: 400;
    color: rgb(247, 8, 8);
    text-shadow: 0 -1000px 100px, 0 0 2px, 0 0 1em rgb(216, 13, 13), 0 0 0.5em red, 0 0 0.1em red, 0 10px 3px #000;
    line-height: .8;
}

.animate-logo{
    animation: logo-color 10s ease infinite;
}

@keyframes logo-color {
    0%{
        color: red;
    }
    20%{
        color: chartreuse;
    }
    40%{
        color: violet;
    }
    60%{
        color: yellow;
    }
    80%{
        color:deeppink;
    }
    100%{
        color: red;
    }
}

@media screen and (max-width:1000px){
    .logo{
        font-size: 70px;
    }
}

@media screen and (max-width:768px){
    .logo{
        font-size: 60px;
    }
}

@media screen and (max-width:500px){
    .logo{
        font-size: 60px;
    }
}

@media screen and (max-width:400px){
    .logo{
        font-size: 50px;
    }
}
