.uptop-event-img-desktop {
  width: 100%;
  background-repeat: no-repeat;
  max-width: 800px;
  opacity: .8;
}

.uptop-event-img-mobile {
  width: 100%;
  max-width: 800px;
  background-repeat: no-repeat;
  opacity: .8;
  display: none;
}

.uptop-event-img-desktop,
.uptop-event-img-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.uptop-event-img-desktop {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width:500px) {
  .uptop-event-img-desktop {
    display: none;
  }

  .uptop-event-img-mobile {
    max-width: 500px;
    display: block;
  }
}
