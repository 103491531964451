@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

body {
  position: relative;
  min-height: 100vh;
  isolation: isolate;
  color: white;
  /* background-color: rgb(0, 0, 0); */
  background-color: #1d1d1b;
  /* background-image: url('./images/ktm_bar_lounge_logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; */
  background-attachment: fixed;
  max-width: 1500px;
  font-family: 'Playfair Display', serif;
  z-index: 100;
}

body::after {
  content: '';
  position: absolute;
  z-index: -1;
  inset: 0;
  background-color: #1d1d1b;
  opacity: .8;
}

/* .main-bg{
  position: fixed;
  overflow-y: scroll;
  isolation: isolate;
  left: 0;
  right: 0;
  top: 82px;
  bottom: 0;
  padding-top: 82px;
  background-image: url('./images/ktm_bar_lounge_logo.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}
.main-bg::-webkit-scrollbar{
  display: none;
}
.main-bg::after{
  content: '';
  position: fixed;
  z-index: -1;
  inset: 0;
  background-color: rgb(0,0,0);
  opacity: .9;
  overflow-y: scroll;
} */
