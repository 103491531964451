.footer-section{
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.footer-ktm-logo-img{
    width: 100%;
    max-width: 200px;
}
.footer-heading > h1{
    font-size: 60px;
}
.footer-heading{
    margin: .8rem;
}
.social-medias > a{
    text-decoration: none;
    font-size: 60px;
    transition: .3s all ease-in;
}

.social-medias > a:hover{
    opacity: .7;
}

.social-medias > a:first-child{
    margin-right: .5rem;
}
.fa-instagram{
    color: #FD1D1D;
}
.fa-square-facebook{
    color: #3b5998;
}
.footer-copyright{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
}
.footer-copyright > a{
    text-decoration: none;
}

.address-info{
    text-align: center;
    margin: .8rem;
}
.address-info > p{
    margin-bottom: 0;
}
.ktm-footer-phone{
    text-decoration: none;
    color: #d0a44c;
    font-size: 20px;
    font-weight: bold;
}
.ktm-footer-phone:focus{
    outline: none;
}

.ktm-footer-phone:hover{
    color: #d0a44c;
    opacity: .8;
}
