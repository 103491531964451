.img-desc-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.img-desc-img{
    flex: .5;
    overflow: hidden;
}
.img-desc-content{
    flex: .5;
    margin: 10px;
    padding: 10px;
}
.img-desc-img > img{
    width: 100%;
    object-fit: cover;
}
.shape{
    padding: 10px;
    position: relative;
}
.shape::before{
    content: '';
    position: absolute;
    height: 20%;
    width: 20%;
    top: 0;
    right: 0;
    border-right: 3px solid #d0a44c;
    border-top: 3px solid #d0a44c;
}
.shape::after{
    content: '';
    position: absolute;
    height: 20%;
    width: 20%;
    bottom: 0;
    left: 0;
    border-left: 3px solid #d0a44c;
    border-bottom: 3px solid #d0a44c;
}
.img-desc-content > h2{
    color: #d0a44c;
    padding-bottom: 10px;
    border-bottom: 1px solid #d0a44c;
}
.img-desc{
    margin-top: 1rem;
}
.img-desc-content > p:last-child{
    color: #d0a44c;
}
@media screen and (max-width:768px) {
    .img-desc-wrapper{
        flex-direction: column;
    }
    .img-desc-content,
    .img-desc-content{
        flex: 1;
    }
    .img-desc-content{
        flex: .5;
        order: 2;
    }
    .img-desc-img{
        order: 1;
    }
}
